import React from "react";
import LineGradient from "../components/LineGradient";
import { motion } from "framer-motion";

const container = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const projectVariant = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1 },
};

const Project = ({ title, des, appAdress }) => {
  const overlayStyles = `absolute h-full w-full  opacity-0 hover:opacity-90 transition duration-500
    bg-grey z-30 flex flex-col justify-center items-center text-left p-1 text-deep-blue rounded-3xl`;
  const projectTitle = title.split(" ").join("-").toLowerCase();
  
  //const projectTitle2 = title.split(" ").join("-").toLowerCase();

  return (
    <motion.div variants={projectVariant} className="relative">
      
      <div className="relative">
        <div className={overlayStyles}>
        {/*<p className="text-2xl font-playfair">{title}</p>*/}
        <p className="mt-7">{des}</p>
       {/*} <video autoPlay  className="mt-7">
          <source src={Video_1} type="video/mp4"/>
        </video>*/}
      </div>
      <img className="rounded-3xl  w-80  rounded-3xl" src={`../assets/${projectTitle}.png`} alt={projectTitle} />
      </div>
      <div>
      </div>
       <div className="relative mx-auto pb-10 flex justify-center  
              max-w-[400px] max-h-[50px]  font-playfair font-semibold rounded-3xl">
            <a href = {appAdress} target="_blank" rel="noreferrer">
              <img src={"../assets/apple_icon.png"} alt={"apple_icon"} /></a>
          </div>
    </motion.div> 
    
    
  );
};

const Projects = () => {
  return (
    <section id="projects" className="pt-24 pb-48">
      {/* HEADINGS */}
      <motion.div
        className="md:w-2/5 mx-auto text-center"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: -50 },
          visible: { opacity: 1, y: 0 },
        }}
      >
       
        <div>
          <p className="font-playfair font-semibold text-4xl">
            <span className="text-red">PRO</span>JECTS
          </p>
          <div className="flex justify-center mt-5">
            <LineGradient width="w-2/3" />
          </div>
        </div>
        <p className="mt-10 mb-10">
 
        </p>
      </motion.div>

      {/* PROJECTS */}
      <div className="flex justify-center">
        <motion.div
          className="sm:grid sm:grid-cols-2 md:grid-cols-3  gap-10"
          variants={container}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
        >
          {/* ROW 1 */}
          {/*<div
            className="flex justify-center text-center items-center p-10 bg-red
              max-w-[400px] max-h-[400px] text-2xl font-playfair font-semibold"
          >
            BEAUTIFUL USER INTERFACES
          </div>*/}
          <Project title="Math_Train" des="  The application is designed for
           kids to learn simple math exercises. It is a course type 
           application and has the incorrect answer exercise repeating 
           strategy, to help kids to memorize the exercises. It will not 
           let the child to pass the exercise until the exercise is memorized." 
          appAdress="https://apps.apple.com/us/app/math-learning-train/id1198806389"/>
          <Project title="abc_train" des="   The application is designed
           for kids to learn the letters, read, spell and type the first 
           words. It has the incorrect answer repeating strategy, to help
            kids to memorize the alphabet and the words. It will not let 
            the child to pass the alphabet level or the word group until 
            every thing is memorized and the level is done without mistakes." 
          appAdress="https://apps.apple.com/us/app/abc-learning-train/id1243156466
                            "/>

          {/* ROW 2 */}
          <Project title="tracing_icon" des="  The ABC Tracer is design for
           kids to learn the letters, words, numbers, basic geometrical 
           figures by tracing. It has the option to add and trace up 
           to 100 your own words and names . Kids can learn to write his 
           own name or other basic words. The words/names can be added, 
           changed in the list order, or deleted.(add or delete your name 
            or word)"
          appAdress="https://apps.apple.com/us/app/abc-tracer/id1464064282"/>
          <Project title="count_icon_3" des="   The application is designed 
          for kids to learn the numbers, numerical order, and to learn to 
          count. It contains 4 parts:
          1st - tracing numbers (up to 100), 
          2nd - tracing sequences of numbers (up to 100),
          3rd - matching random numbers to a numerically ordered sequence." 
          appAdress = "https://apps.apple.com/us/app/abc-math-learning-train/id1464059995"/>
          <Project title="add_sub_icon2" des="  The application is designed
           for kids to learn simple math exercises. It is a course type 
           application and has the incorrect answer exercise repeating 
           strategy, to help kids to memorize the exercises. It will not 
           let the child to pass the exercise until the exercise is memorized."
          appAdress="https://apps.apple.com/us/app/addition-subtraction-train/id1481099940?mt=8"/>

          {/* ROW 3 */}
          {/*<Project title="_1024" des="descrip of splashy bird"/>
          <Project title="tracing_icon" des="descrip of tracing_train" 
          appAdress="https://apps.apple.com/us/app/abc-tracer/id1464064282"/>
          <div
            className="flex justify-center text-center items-center p-10 bg-blue
              max-w-[400px] max-h-[400px] text-2xl font-playfair font-semibold"
          >
            SMOOTH USER EXPERIENCE
          </div>*/}
        </motion.div>
      </div>
    </section>
  );
};

export default Projects;
