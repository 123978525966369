import React from "react";
import "./Locomotive.css";
// comment
function Locomotive() {
    return(
        <div className="LocSuperBox">
           <div className="steam" id="steam1"> </div>
           <div className="steam" id="steam2"> </div>
           <div className="steam" id="steam3"> </div>
           <div className="steam" id="steam4"> </div>

             <div className="Locbox">
              
               
      {/*}  <img src="images/smog.png" className="Smog" alt="smog" />*/}
          <img src="images/locomotive33.png" className="Loc" alt="loc" />
      
          <div className="Wheelbox"> 
            <img src="images/wheel_3.png" className="Wheel_1" alt="wheel" />
            <img src="images/wheel_3.png" className="Wheel_2" alt="wheel" />
            <img src="images/wheel_3.png" className="Wheel_3" alt="wheel" />
            <img src="images/wheel_3.png" className="Wheel_4" alt="wheel"/>
            <div className="Barabox_1">
              <img src="images/bara_4.png" className="Bara_1" alt="bara_1" />
            </div>
            <div className="Barabox_2">
              <img src="images/bara_44.png" className="Bara_2" alt="bara_2" />
            </div>
            
          </div>
          
          <div className="Roadbox">
              <img src="images/rail_road3.png" className="Road" alt="road" />
            </div>
        </div> 
        </div>
       
    );
}

export default Locomotive;