import SocialMediaIcons from "../components/SocialMediaIcons";

const Footer = () => {
  return (
    <footer className="h-64 bg-red pt-10">
      <div className="w-10/12 mx-auto">
        
        <div className="md:flex justify-center md:justify-between text-center ">
          <SocialMediaIcons />
          <div className="font-playfair font-semibold text-2xl text-yellow mt-10">
            MIKHAR APPS
          </div>
          
        </div>
      </div>
    </footer>
  );
};

export default Footer;
