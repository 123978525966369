import LineGradient from "../components/LineGradient";
import { motion } from "framer-motion";

const Testimonials = () => {
  return (

  
    <section id="privacy" className="pt-24 pb-16">
          <motion.div
       className="md:w-2/5 mx-auto text-center"
       initial="hidden"
       whileInView="visible"
       viewport={{ once: false, amount: 0.5 }}
       transition={{ duration: 0.5 }}
       variants={{
         hidden: { opacity: 0, y: -50 },
         visible: { opacity: 1, y: 0 },
       }}
     >
    
       <div>
          <p className="font-playfair font-semibold text-4xl">
            OUR<span className="text-red"> PRIVACY</span>
          </p>
          <div className="flex justify-center mt-5">
            <LineGradient width="w-2/3" />
          </div>
        </div>
        {/*<LineGradient width="mx-auto w-2/5" />*/}
        </motion.div> 
        <motion.div
          className="mx-auto relative bg-red max-w-full h-full flex flex-col justify-end p-16 mt-4
            before:absolute before:top-[-130px] before:-ml-[110px] before:left-1/2 rounded-3xl "
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.6 }}
          variants={{
            hidden: { opacity: 0, scale: 0.8 },
            visible: { opacity: 1, scale: 1 },
          }}
        >  
           <p>Protecting user privacy is paramount in apps,  More particularly:</p>
            <p>            
                <em>Data Collection and Storage:</em><br />
            We do not access, collect or store any customers data. Our apps doesn’t include account-based( Account Sign-In) features</p>

            <p>            
            <em>Data Use and Sharing:</em><br />
            We do not access, collect, store use, or share any customers data. Our apps do not use or share someone’s personal data. Our apps dont ask users to identify as anonymous users and do not collect data from Apple-provided APIs</p>

            <p>            
            <em>Health and Health Research:</em><br />
            Our apps does not collect health, fitness, and medical research context from the Clinical Health Records API, HealthKit API, Motion and Fitness, MovementDisorderAPIs data. Our apps does not conduct health-related human subject research</p>

            <p>
            <e>Kids:</e><br />
            We do not access, collect or store any customers data. Our apps does not collect any names, addresses, emails, locations, photos.  We do our best in complying with laws and requirements like the Children’s Online Privacy Protection Act (“COPPA”), the European Union’s General Data Protection Regulation (“GDPR”)</p>

            <p>
            <em>Location Services:</em><br />
             Our apps does not use Location-based APIs </p> 
                
       
      </motion.div>
      {/*
      {/* HEADING 
      <motion.div
        className="md:w-1/3 text-center md:text-left"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: -50 },
          visible: { opacity: 1, x: 0 },
        }}
      >
        <p className="font-playfair font-semibold text-4xl mb-5 text-red">
          OUR PRIVACY
        </p>
        <LineGradient width="mx-auto w-2/5" />     

      </motion.div>

      {/* TESTIMONIALS 
      <div className="md:flex md:justify-between gap-8">
        <motion.div
          className="mx-auto relative bg-blue max-w-[400px] h-[350px] flex flex-col justify-end p-16 mt-48
            before:absolute before:top-[-120px] before:-ml-[110px] before:left-1/2 before:content-person1"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.6 }}
          variants={{
            hidden: { opacity: 0, scale: 0.8 },
            visible: { opacity: 1, scale: 1 },
          }}
        >
          <p className="font-playfair text-6xl">“</p>
          <p className="text-center text-xl">
            A auctor pharetra hendrerit mattis amet etiam interdum platea.
          </p>
        </motion.div>

        <motion.div
          className="mx-auto relative bg-red max-w-[400px] h-[350px] flex flex-col justify-end p-16 mt-48
            before:absolute before:top-[-120px] before:-ml-[110px] before:left-1/2 before:content-person2"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.6 }}
          variants={{
            hidden: { opacity: 0, scale: 0.8 },
            visible: { opacity: 1, scale: 1 },
          }}
        >
          <p className="font-playfair text-6xl">“</p>
          <p className="text-center text-xl">
            Aliquam aliquet integer ut fames odio in at. At magna ornare dictum
            lectus.
          </p>
        </motion.div>

        <motion.div
          className="mx-auto relative bg-yellow max-w-[400px] h-[350px] flex flex-col justify-end p-16 mt-48
            before:absolute before:top-[-120px] before:-ml-[110px] before:left-1/2 before:content-person3"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.4, duration: 0.6 }}
          variants={{
            hidden: { opacity: 0, scale: 0.8 },
            visible: { opacity: 1, scale: 1 },
          }}
        >
          <p className="font-playfair text-6xl">“</p>
          <p className="text-center text-xl">
            Fames odio in at. At magna ornare dictum lectus.
          </p>
        </motion.div>
      </div>*/}
    </section>
  );
};

export default Testimonials;
